<!--This default route will act as a 404 - Not Found page (a fallback in the event no route is found)-->

<template>
    <v-container v-if="$store.state.user">
        <v-row class="text-center mt-10">
            <v-col class="mb-2">
                <h3 class="display-2 font-weight-bold mb-3">404</h3>
                <p>This page no longer exists or was moved to another location.</p>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
export default {
    name: 'PageNotFound'

}
</script>